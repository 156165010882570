
form {
}

.field-validation-error {
	color: #c4000a; 
	display: block;
}

.eco_search_form, .eco_search_form1 {
	position: absolute;
	color: #fff;
	right: 0;
	z-index: 150;
	width: 100%;
	background: #333;
	min-width: 200px;
	padding: 10px;
	display: none;
	top: 100%;
}

.control-group {
	margin-top: 15px;
}

.submit-group {
	text-align: right;
	margin-top: 30px;
}

.login.submit-group {
	display: flex;
	justify-content: space-between;
}

	.login.submit-group .eco_btn {
		margin-top: 0;
	}

.eco_label,
.umbraco-forms-page label{
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 5px;
}

.eco_textarea
/*.umbraco-forms-page textarea*/ {
	height: 76px;
}

.eco_input:not(.pub-search-input),
.umbraco-forms-page input {
	height: 38px;
}

input[type=file]::file-selector-button {
	background: #333333;
	border-radius: 5px;
	color: white;
	font-size: 12px;
	line-height: 20px;
	font-weight: 600;
	padding: 0px 12px;
	height: 38px;
	box-shadow: none;
	outline: none;
	margin-right: 15px;
	cursor: pointer;
}

.eco_input, .eco_textarea,
.umbraco-forms-page input[type="text"], .umbraco-forms-page textarea,
.umbraco-forms-page input[type="email"],
.umbraco-forms-page input[type="tel"] {
	/*background: #fff;*/
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #111;
	/*padding-left: 6px;*/
	font-size: 16px;
	width: 100% !important;
	/*padding-top: 7px;
	padding-bottom: 7px;*/
	padding: 7px;
	max-width: 100% !important;
}

	.eco_radio, .eco_checkbox {
		width: 18px;
		height: 18px;
		margin-right: 6px;
	}

.eco_radio_label, .eco_checkbox_wrapper span {
	display: flex;
	align-items: center;
}
.eco_radio:hover, .eco_radio:focus,
.eco_checkbox:hover, .eco_checkbox:focus {
	-webkit-appearance: auto !important;
	cursor: pointer;
}

.radio_options > * {
	padding-right: 0px !important;
}

.eco_checkbox_wrapper .eco_label {
	margin-bottom: 10px;
}

.eco_checkbox_wrapper .eco_checkbox_label {
    display: block;
}

.eco_checkbox_wrapper .eco_checkbox_label:not(:last-child) {
	margin-bottom: 10px;
}

.astrx,
.umbraco-forms-indicator {
	color: #f00;
	margin-left: 3px;
	font-weight: 600;
	font-size: 14px;
}

.umbraco-forms-indicator {
	margin-left: 0px;
}

.custom-file-input label {
	color: white;
	background-color: #333;
	border-radius: 5px;
	cursor: pointer;
	padding: 8px;
}

.custom-file-input span {
	margin-left: 6px;
}

.mb-10 {
	margin-bottom: 10px !important;
}

.delete-image, .delete-cimage, .delete-video {
	appearance: none;
	background-color: transparent;
	color: red;
	font-size: 30px;
	opacity: .8;
	outline: none;
	position: absolute;
	right: 13px;
	top: 1px;
	font-weight: bold;
	border: 2px solid #000;
	border-radius: 4px;
	z-index: 1;
}
	.delete-image span,
	.delete-cimage span,
	.delete-video  span{
		margin: 0;
		line-height: 30px;
		display: inline-block;
	}

	.delete-image:hover,
	.delete-cimage:hover,
	.delete-video:hover {
		color: #f00;
		opacity: 1;
	}

.files {
	margin-top: 12px;
}

.umbraco-forms-page legend {
	text-transform: uppercase;
	font-size: 20px;
	line-height: 30px;
	font-family: Oswald,sans-serif;
	font-weight: 500;
	margin-bottom:30px;
}

.umbraco-forms-field-wrapper {
	padding-bottom: 15px;
}

.umbraco-forms-form fieldset {
	padding: 0px;
	margin: 0px;
}

input.btn {
	background: #445d1b;
	border: none;
	border-bottom: 4px solid #587e35;
	border-radius: 0;
	color: #fff !important;
	display: inline-block;
	font-size: 14px;
	margin-top: 15px;
	padding: 14px 46px;
	text-transform: uppercase;
	width: auto !important;
	height: auto;
	float: right;
}

/*.umbraco-forms-field.checkbox {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	gap: 10px;
	align-items: center;
}*/

	.umbraco-forms-field.checkbox .umbraco-forms-field-wrapper {
		padding-bottom: 0px;
	}

	/*.umbraco-forms-form .checkbox input {
		width: 20px !important;
	}*/

/*.umbraco-forms-form .checkboxlist label {
	float: right !important;
	clear: right !important;
}*/

.umbraco-forms-field-wrapper {
	position: relative;
}

.umbraco-forms-field.checkbox {
	padding-top: 10px;
	padding-bottom: 20px;
}

.umbraco-forms-field.checkbox label {
	padding-left: 32px;
	cursor: pointer;
	position: relative;
	top: -4px;
}

.umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist label,
.umbraco-forms-field.dataconsent label:not(:first-child) {
	padding-left: 10px;
	cursor: pointer;
	position: relative;
	top: -4px;
	font-weight: normal;
}

.umbraco-forms-field.multiplechoice .umbraco-forms-field-wrapper .checkboxlist {
	padding-top: 8px;
}

.umbraco-forms-field.checkbox input[type="checkbox"] {
	border: 1px solid #ddd;
	position: absolute;
	top: 2px;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: transparent;
	pointer-events: none;
}

.umbraco-forms-field.multiplechoice input[type="checkbox"],
.umbraco-forms-field.dataconsent input[type="checkbox"] {
	border: 1px solid #ddd;
	left: 0;
	height: 18px;
	width: 18px;
	background-color: transparent;
}

.umbraco-forms-field.multiplechoice .checkboxlist {
	display: block;
}

.umbraco-forms-field select {
	margin: 0px 15px;
	min-width: 67px !important;
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 5px;
	color: #111;
	font-size: 16px;
	padding: 7px;
}

.umbraco-forms-field.dataconsent {
	padding-top: 10px;
}

.umbraco-forms-field.dataconsent label:first-child,
.umbraco-forms-field.fileupload label {
	display: block;
}

.umbraco-forms-field.singlechoice .radiobuttonlist {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding-top: 5px;
	padding-bottom: 4px;
}

.umbraco-forms-field.singlechoice .radiobuttonlist input[type="radio"] {
	width: 18px;
	height: 18px;
}

@media (min-width: 768px) {
	.umbraco-forms-fieldset .umbraco-forms-container:not(:first-child) {
		padding-right: 0px;
	}

	.umbraco-forms-field.checkbox .help-block {
		 padding-left: 32px; 
	}
}

@media (max-width: 479px) {
	input.btn {
		padding: 14px 30px;
	}
}